// Utility
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type Nullable<T> = {
    [P in keyof T]: null | T[P];
};

// General
export type Locale = 'en' | 'fi' | 'sv';
export type Language = 'ENGLISH' | 'FINNISH' | 'SWEDISH';
export type RiskLevel = 'GOOD' | 'MINOR' | 'MODERATE' | 'SEVERE' | 'NONE';
export type Priority = 'LOW' | 'NORMAL' | 'HIGH';
export type Probability = 'NEVER' | 'UNLIKELY' | 'PROBABLE' | 'LIKELY';
export type TaskStatus = 'NEW' | 'OPEN' | 'COMPLETED' | 'REJECTED';
export type Date = string;
export enum Permission {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE',
  DeleteSelf = 'DELETE_SELF',
  DeleteAll = 'DELETE_ALL',
};
export enum RestrictedResource {
  Observation = 'observation',
  ObservationComment = 'observation_comment',
  Task = 'task',
  TaskComment = 'task_comment',
  Assessment = 'assessment',
  Monitoring = 'monitoring',
  Statistics = 'statistics',
  Form = 'form',
  Report = 'report',
};
export type Role = 'VIEWER' | 'USER' | 'MANAGER';

// GraphQL & Models
export type Id = string;
export type RestId = string;
export interface Link {
  href: string;
};

export interface Config {
  apiUrl: string;
  nativeDatePicker: boolean;
  tenantLink?: null | string;
  tenants: { [id: number]: string };
  userRestId: number;
  maxUploadSize: string;
  contextPath: string /* TODO: not needed? */;
  csrf?: {
    token: string;
    parameterName: string;
    headerName: string;
  };
  language: Locale;
  locale: string;
  tenantHref: string;
  userId: string | number;
  systemAdmin: boolean;
  tenantAdmin: boolean;
  blobApiUrl: string;
  termsAccepted: boolean;
  tenantId: number | null;
  fullUserName: string;
	userHref: string;
};

export interface FullLocation {
  id: Id;
  restId: RestId;
  name: string;
  parentLocationId: RestId;
  links: {
    self: Link;
  };
};

export interface FullUser {
  id: Id;
  restId: RestId;
  fullName: string;
  email: string;
};

export enum ObservationStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  WaitingReview = 'WAITING_REVIEW',
}
export interface FullObservation {
  id: Id;
  restId: RestId;
  __typename: 'observation';
  version: number;
  risk: RiskLevel;
  probability: Probability;
  subject: string;
  status: ObservationStatus;
  description: string;
  observationDate: Date;
  createdBy: FullUser;
  assigned: FullUser;
  assignedId: number;
  assignedName: string;
  keyWords: string[];
  tasks: FullTask[];
  location: FullLocation;
  notifier?: string;
  createdByName?: string;
};

export interface FullQuestion {
  restId: RestId;
  texts: {
    [language: string]: string;
  };
  instructions: {
    [language: string]: string;
  };
}

export interface FullQuestionGroup {
  id: Id;
  restId: RestId;
  titles: {
    [language: string]: string;
  };
};

export interface FullAssessmentAnswer {
  id: Id;
  restId: RestId;
  question: FullQuestion;
};

export interface FullAssessmentFormGroup {
  id: Id;
  assessmentForm: FullAssessmentForm;
  orderNumber: number;
  questionGroup: FullQuestionGroup;
};

export enum AssessmentFormType {
  Assessment = 'ASSESSMENT',
  Monitoring = 'MONITORING',
};
export interface FullAssessmentForm {
  description: string;
  type?: AssessmentFormType;
  assessmentFormGroups: FullAssessmentFormGroup[];
};

export interface FullAssessmentParticipant {
  assessment: FullAssessment;
  participant: FullUser;
  participantName: string;
  realName: string;
};

export interface FullAssessmentStats {
  id: Id;
  observations: number | null;
  tasks: number | null;
  attachments: number | null;
};

export interface FullAssessment {
  id: Id;
  restId: RestId;
  name: string;
  created: Date;
  status: 'OPEN' | 'COMPLETED' | 'CANCELLED';
  assessmentForm: FullAssessmentForm;
  location: FullLocation;
  assessmentParticipants: FullAssessmentParticipant;
  assessmentStats: FullAssessmentStats[];
};

export interface FullMonitoringAnswer {
  restId: RestId;
  id: Id;
  version: number;
  question: FullQuestion;
  okCases?: number; // TODO: force to never be null
  failCases?: number;
};

export interface FullMonitoringParticipant {
  monitoring: FullMonitoring;
  participant: FullUser;
  participantName: string;
  realName: string;
};

export interface FullMonitoringStats {
  id: Id;
  observations: number;
  tasks: number;
  attachments: number;
};

export interface FullMonitoring {
  id: Id;
  restId: RestId;
  name: string;
  created: Date;
  status: 'OPEN' | 'COMPLETED' | 'CANCELLED';
  assessmentForm: FullAssessmentForm;
  location: FullLocation;
  monitoringParticipants: FullMonitoringParticipant[];
  monitoringStats: FullMonitoringStats[];
};

export interface FullTask {
  id: Id;
  restId: RestId;
  status: TaskStatus;
  subject: string;
  location: FullLocation;
};

export interface FullComment {
  id: Id;
  restId: RestId;
  created: Date;
  userName: string;
  text: string;
  links: {
    self: Link;
    replyTo: Link;
  };
};

export interface FullCommentThread {
  restId: RestId;
  comments: FullComment[];
};

export interface FullAttachment {
  id: Id;
  restId: RestId;
  created: Date;
  fileName: string;
  length: number;
  resource: string;
};
