import withFeedback, { InjectedFeedbackProps } from 'components/FeedbackModal/withFeedback';
import * as csx from 'csx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, RouteProps } from 'react-router';
import { style } from 'typestyle';

const errorClass = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  $nest: {
    '& > *': {
      width: csx.percent(50),
      fontSize: 23,
    },
  },
});

interface State {
  hasError: boolean;
  pathname?: string;
};
class PageRoute extends React.PureComponent<RouteProps & InjectedFeedbackProps, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromProps(props: any, state: State) {
    if(state.hasError && props.location && props.location.pathname !== state.pathname) {
      return { hasError: false };
    }

    return null;
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({
      hasError: true,
      pathname: this.props.location ? this.props.location.pathname : undefined,
    });

    // Send feedback automatically in production
    if(process.env.NODE_ENV === 'production') {
      // Simple check that avoids re-sending feedback if user gets error and refreshes page.
      // We use sessionStorage to store all of the errors encountered during this session. If
      // user opens a URL where we already encountered an error, we won't send a feedback issue.
      const key = '$rh_page_route_errors';
      const errors: string[] = JSON.parse(sessionStorage.getItem(key) || '[]');
      const url = window.location.href;
      if(errors.includes(url)) {
        return;
      }

      errors.push(url);
      sessionStorage.setItem(key, JSON.stringify(errors));

      this.props.sendFeedback({
        description: `${error.message}
          <pre><code class="text">
          ${info.componentStack}
          </code></pre>
        `,
        type: 'Error',
      });
    }
  }

  render() {
    if(this.state.hasError) {
      return (
        <div className={errorClass}>
          <FormattedMessage
            id='error.handled-automatically'
            defaultMessage={`Sorry, an unexpected error occurred. This has been automatically
            reported to the system administrators. You can go back or try refreshing this page.`}
          />
        </div>
      );
    }

    return <Route {...this.props}/>
  }
}

export default withFeedback(PageRoute);
