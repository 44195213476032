import { Checkbox, Textarea, Tooltip } from '@resys/opux-react';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ValueSelect from 'components/ValueSelect';
import { opuxRed } from 'opux/styles';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { style } from 'typestyle';
import withFeedback, { InjectedFeedbackProps } from './withFeedback';

const clearClass = style({
  clear: 'both',
  marginBottom: 10,
});

const selectClass = style({
  marginBottom: 10,
});

const errorClass = style({
  color: opuxRed.toHexString(),
})

enum Action {
  Praise = 'Praise',
  Propose = 'Propose',
  Bug = 'Bug',
};

enum RequestState {
  NotSent, Loading, Success, Error,
}

const actionMessages = defineMessages({
  [Action.Praise]: {
    id: 'feedback.praise',
    defaultMessage: 'Praise',
  },
  [Action.Propose]: {
    id: 'feedback.propose-feature',
    defaultMessage: 'Proposal',
  },
  [Action.Bug]: {
    id: 'feedback.problem',
    defaultMessage: 'Problem',
  },
});

const valueToColor = {
  [Action.Praise]: 'green',
  [Action.Propose]: 'blue',
  [Action.Bug]: 'red',
};

interface Props extends InjectedFeedbackProps {
  onClose: () => void;
};
interface State {
  action?: Action;
  description: string;
  allowDebugData: boolean;
  requestState: RequestState;
};
class FeedbackModal extends React.PureComponent<Props, State> {
  state: State = {
    action: undefined,
    description: '',
    allowDebugData: false,
    requestState: RequestState.NotSent,
  };

  setAction = (action: Action | null) => {
    this.setState({ action: action || undefined });
  }

  setDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ description: event.currentTarget.value });
  }

  toggleAllowDebugData = () => {
    this.setState({ allowDebugData: !this.state.allowDebugData });
  }

  handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  }

  send = async () => {
    const { sendFeedback } = this.props;
    const { action, description } = this.state;
    if(!action) return;

    this.setState({ requestState: RequestState.Loading });
    try {
      await sendFeedback({
        type: action,
        description,
      });
      this.setState({ requestState: RequestState.Success });
      setTimeout(() => this.props.onClose(), 2500);
    } catch(e) {
      this.setState({ requestState: RequestState.Error });
      setTimeout(() => this.props.onClose(), 3500);
    }
  }

  render() {
    const { onClose } = this.props;
    const { action, description, allowDebugData, requestState } = this.state;

    if(requestState === RequestState.Error) {
      return (
        <Modal open>
          <Modal.Content>
            <Modal.Header textAlign='center' className={errorClass}>
              <FormattedMessage
                id='feedback.error'
                defaultMessage={`An error occurred while sending feedback! Please directly notify
                the administrator of this.'`}
              />
            </Modal.Header>
          </Modal.Content>
        </Modal>
      )
    } else if(requestState === RequestState.Success) {
      return (
        <Modal open>
          <Modal.Content>
            <Modal.Header textAlign='center'>
              <FormattedMessage id='feedback.thanks' defaultMessage='Thank you for your feedback!'/>
            </Modal.Header>
          </Modal.Content>
        </Modal>
      )
    }

    return (
      <Modal open>
        <Modal.Content>
          <Modal.Header>
            <FormattedMessage id='feedback.send' defaultMessage='Send feedback'/>
          </Modal.Header>
          <ValueSelect
            fluid
            valueColor={valueToColor}
            onChange={this.setAction}
            selected={action}
            className={selectClass}
          >
            {value => (
              <span>
                {value === Action.Praise && <Icon name='smiley-happy'/>}
                {value === Action.Propose && <Icon name='smiley-neutral'/>}
                {value === Action.Bug && <Icon name='smiley-sad'/>}
                &nbsp;
                <FormattedMessage {...actionMessages[value]}/>
              </span>
            )}
          </ValueSelect>
          <Textarea rows={6} onChange={this.setDescription} value={description}/>
          <div className={clearClass}/>
          <Checkbox checked={allowDebugData} onChange={this.toggleAllowDebugData}>
            <FormattedMessage
              id='feedback.allow-session-data'
              defaultMessage='Give permission to send session data with your feedback.'
            />
            <Tooltip
              trigger={<Icon onClick={this.handleIconClick} name='info'/>}
              position='top'
              on='hover'
            >
              <FormattedMessage
                id='feedback.tooltip.session-data-explanation'
                defaultMessage={`Session information that will be sent contains
                potentially identifiable information.`}
              />
            </Tooltip>
          </Checkbox>
        </Modal.Content>
        <Modal.Footer>
          <Button
            primary
            loading={requestState === RequestState.Loading}
            onClick={this.send}
            disabled={!action}
          >
            <FormattedMessage id='send' defaultMessage='Send'/>
          </Button>
          <Button secondary onClick={onClose}>
            <FormattedMessage id='cancel' defaultMessage='Cancel'/>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const WrappedFeedbackModal = withFeedback(FeedbackModal);
export default WrappedFeedbackModal;
