import '0polyfills';
import client from 'apollo/client';
import AppUpdateMessage from 'components/AppUpdateMessage';
import { basename } from 'const';
import { LocaleProvider } from 'context/locale-provider';
import { QueryProvider } from 'context/query';
import { getConfig } from 'globalConfig';
// @ts-ignore
import messages from './intl';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import ReactDOM from 'react-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fi from 'react-intl/locale-data/fi';
import sv from 'react-intl/locale-data/sv';
import Modal from 'react-modal';
import { BrowserRouter as Router } from 'react-router-dom';
import * as storage from 'settings/storage';
import 'styles/custom.css';
import 'styles/main.css';
import 'styles/opux-custom.css';
import { Locale } from 'types';
import App from 'views/App';
import * as serviceWorker from './serviceWorker';
// In your application's entrypoint
import { enableMapSet } from 'immer';

enableMapSet();

// Locale data
addLocaleData([...en, ...fi, ...sv]);

const Root = ({ locale }: { locale: Locale }) => (
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <QueryProvider>
        <LocaleProvider value={locale}>
          {({ selectedLocale }) => (
            <IntlProvider
              locale={selectedLocale}
              key={selectedLocale}
              messages={messages[selectedLocale]}
            >
              <Router basename={basename}>
                <App/>
              </Router>
            </IntlProvider>
          )}
        </LocaleProvider>
      </QueryProvider>
    </ApolloHooksProvider>
  </ApolloProvider>
);

const element = document.getElementById('root');
if(!element) {
  console.error(`No 'root' element found!`);
} else {
  if(process.env.REACT_APP_PWA === 'true') {
    serviceWorker.register({
      onUpdate: () => {
        const messageEl = document.createElement('div');
        document.body.appendChild(messageEl);
        ReactDOM.render(<AppUpdateMessage/>, messageEl);
      },
      workerPath: process.env.NODE_ENV === 'production' ? '/service-worker.js' : undefined,
    });
  } else {
    serviceWorker.unregister();
  }
  (async () => {
    const config = await getConfig();
    Modal.setAppElement(element);
    const locale = (config && config.language) || storage.get<Locale>('locale');
    ReactDOM.render(<Root locale={locale || 'fi'} />, element);
  })();
}
