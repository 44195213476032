import gql from 'graphql-tag';
import React from 'react';
import { ChildProps, graphql } from 'react-apollo';
import { Omit } from 'types';

const SEND_FEEDBACK = gql`
  mutation sendFeedback(
    $type: String!,
    $description: String!,
    $timestamp: String!,
    $url: String!,
    $userAgent: String!
  ) {
    sendFeedback(
      input: {
        description: $description,
        userAgent: $userAgent,
        timestamp: $timestamp,
        url: $url,
        type: $type
      }
    ) @rest(
      path: "/feedbacks", method: "POST", type: "feedback"
    ) {
      description
    }
  }
`;

interface FeedbackData {
  type: 'Praise' | 'Propose' | 'Bug' | 'Error';
  description: string;
}
export interface InjectedFeedbackProps {
  sendFeedback: (data: FeedbackData) => Promise<void>;
};

function withFeedback<
  Props extends InjectedFeedbackProps,
  ComponentProps = Omit<Props, 'sendFeedback'>,
>(WrappedComponent: React.ComponentType<Props>): React.ComponentClass<ComponentProps & {}> {
  return graphql<ComponentProps & {}>(SEND_FEEDBACK)(
    class FeedbackWrapper extends React.Component<ChildProps<ComponentProps>> {
      send = async (data: FeedbackData) => {
        const { mutate } = this.props;
        if(!mutate) return;

        return mutate({
          variables: {
            type: data.type,
            description: data.description,
            timestamp: Date.now(),
            userAgent: window.navigator.userAgent,
            url: window.location.href,
          },
        });
      }

      render() {
        const { mutate, ...restProps } = this.props;
        return (
          <WrappedComponent
            // TODO: Bug in TS3.2, see https://github.com/Microsoft/TypeScript/issues/28748
            {...restProps as any}
            sendFeedback={this.send}
          />
        );
      }
    }
  );
}

export default withFeedback;
